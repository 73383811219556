
import {defineComponent} from 'vue'

export default defineComponent({
    name: "TimeTableBackground",
    methods: {

    },
    data() {
        return {
            hours: [] as number[]
        }
    },
    mounted() {
        for(let i = 7; i <= 20; i++) {
            this.hours.push(i)
        }
    }
})
