import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_event_component = _resolveComponent("event-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.events, (displayEvent) => {
      return (_openBlock(), _createBlock(_component_event_component, {
        key: displayEvent.event.id,
        "display-event": displayEvent,
        "start-date": _ctx.getFormattedDate(_ctx.getDate(displayEvent.event.startDate)),
        "end-date": _ctx.getFormattedDate(_ctx.getDate(displayEvent.event.endDate))
      }, null, 8, ["display-event", "start-date", "end-date"]))
    }), 128))
  ]))
}