

import EventComponent from "@/components/EventComponent.vue";
import {defineComponent} from "vue";
import basicStore from "@/store/BasicStore";

const timeFormatOptions : Intl.DateTimeFormatOptions = {hour: '2-digit', minute: '2-digit'};

export default defineComponent({
    components: { EventComponent },
    props: {
        date: {
            type: Date,
        },
        day: {
            type: String,
            default: ""
        },
        events: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            dayStart: this.date as unknown as string,
        };
    },
    beforeMount() {
        const newDate = new Date(Date.parse(this.dayStart))
        basicStore.commit('changeLastEvent',newDate.valueOf())
    },
    methods: {
        getDate(dateString : string) {
            return new Date(Date.UTC(
                parseInt(dateString.slice(0, 4), 10),
                parseInt(dateString.slice(4, 6), 10) - 1,
                parseInt(dateString.slice(6, 8), 10),
                parseInt(dateString.slice(9, 11), 10),
                parseInt(dateString.slice(11, 13), 10),
                parseInt(dateString.slice(13, 15), 10))
            )
        },
        getFormattedDate(date : Date) {
            return date.toLocaleTimeString('fr-FR',timeFormatOptions);
        },
    }
});
