
import {reactive} from "vue";
export default {
    name: "RegisterPage",
    setup: function () {
        const data = reactive({
            name: "",
            surname: "",
            email: "",
            password: ""
        });

        const submit = async () => {
            console.log(JSON.stringify(data))
            await fetch('https://edt-iutnantes.fr/api/register', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            });
        }

        return {
            data,
            submit
        }
    }
}
