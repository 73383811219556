
import NavBar from "@/components/NavBar.vue";

export default {
    components: {NavBar},
    methods: {

    }
}


