

import {defineComponent} from "vue";
import startOfWeek from 'date-fns/startOfWeek';
import addDays from 'date-fns/addDays';
import EventDay from "@/components/EventDay.vue";
import basicStore from "@/store/BasicStore";
import DayData from "@/interfaces/DayDataInterface";
import Event from "@/interfaces/EventInterface";
import TimeTableBackground from "@/components/TimeTableBackground.vue";
import DisplayEvent from "@/interfaces/DisplayEventInterface";
import Edt from "@/interfaces/EdtInterface";

const options : Intl.DateTimeFormatOptions = {weekday: 'long', day: 'numeric', month: 'short'};
const optionsTop : Intl.DateTimeFormatOptions = {weekday: 'long', day: 'numeric', month: 'long'};

export default defineComponent({
    name: "EdtPage",
    components: { EventDay },
    data() {
        return {
            week: [] as DayData[],
            events: [] as Event[],
            weekNum: 0,
            startDay: "",
            endDay: "",
            displayBar: true,
            currentEdt: null as unknown as Edt,
            edtList: [] as Edt[],
            showPredefined: true,
            showFavourites: false,
            hours: [] as number[]
        };
    },
    created() {
        for(let i = 7; i <= 20; i++) {
            this.hours.push(i)
        }
    },
    async mounted() {
        this.loadEdtList();
        if(this.currentEdt != null) {
            await this.changeEdt(this.currentEdt)
        }
    },
    methods: {

        async getEvents (loc : string, icsCode : string) {
            return await fetch('https://edt-iutnantes.fr/public/getEvents?loc=' + loc + '&icscode=' + icsCode,{
                method:'GET',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                }
            })
                .then(res => res.json())
        },

        loadEdtList() {
            this.edtList = [
                new Edt("info_1_2_1","INFO 1 TP2-1","g3165","iut_nantes"),
                new Edt("info_2_alt","INFO 2 ALT","g191826","iut_nantes"),
                new Edt("info_2_1","INFO 2 Groupe 1","g3178","iut_nantes"),
                new Edt("info_2_2","INFO 2 Groupe 2","g3179","iut_nantes"),
                new Edt("info_2_2_1","INFO 2 TP2-1","g3187","iut_nantes"),
                new Edt("info_2_2_2","INFO 2 TP2-2","g3188","iut_nantes"),
                new Edt("info_2_3","INFO 2 Groupe 3","g3180","iut_nantes"),
                new Edt("info_2_4","INFO 2 Groupe 4","g3182","iut_nantes"),
                new Edt("info_2_4_1","INFO 2 TP4-1","g3191","iut_nantes"),
                new Edt("info_2_4_2","INFO 2 TP4-2","g3192","iut_nantes"),
                new Edt("gb_2_alt","GB 2 ALT","g1349721","la-roche-sur-yon"),
            ]
        },

        async changeEdt(edt : Edt) {
            try {
                await this.getEvents(edt.location,edt.icsCode).then((events) => {
                    this.events = events;
                });

                const date = new Date(Date.now())

                basicStore.commit('changeDate',new Date(Date.now()).valueOf())
                this.changeWeek()
                this.currentEdt = edt;
            } catch(err) {
                console.log(err)
            }
        },
        getDaysInWeek(weekNumber : number, year : number) {
            const startDate = startOfWeek(new Date(year, 0, (weekNumber) * 7), { weekStartsOn: 1 }); // 1 pour le lundi comme début de semaine
            const days = [];

            for (let i = 0; i < 7; i++) {
                days.push(addDays(startDate, i));
            }

            return days;
        },

        getWeek(date : Date) {
            const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
            const daysOffset = firstDayOfYear.getDay() - 1;
            const dayOfYear = (Math.floor((date.valueOf() - firstDayOfYear.valueOf()) / (1000 * 60 * 60 * 24))) + 1;
            return Math.ceil((dayOfYear + daysOffset) / 7);
        },

        changeDate(change : number) {
            let date = basicStore.state.date
            basicStore.commit('changeDate',new Date(date + (1000 * 60 * 60 * 24 * change * 7 )).valueOf())
            this.changeWeek();
        },

        getDate(dateString : string) {
            return new Date(Date.UTC(
                parseInt(dateString.slice(0, 4), 10),
                parseInt(dateString.slice(4, 6), 10) - 1,
                parseInt(dateString.slice(6, 8), 10),
                parseInt(dateString.slice(9, 11), 10),
                parseInt(dateString.slice(11, 13), 10),
                parseInt(dateString.slice(13, 15), 10))
            )
        },

        changeWeek() {
            const date = new Date(basicStore.state.date);
            const newDays = this.getDaysInWeek(this.getWeek(date),date.getFullYear());
            this.weekNum = this.getWeek(date);
            this.startDay = newDays[0].toLocaleDateString('fr-FR',optionsTop)
            this.endDay = newDays[6].toLocaleDateString('fr-FR',optionsTop)
            this.week = []

            for(let newDay of newDays) {
                let day : DayData = {
                    day: newDay.toLocaleDateString('fr-FR',options).toString(),
                    date: new Date(newDay.setHours(7)),
                    events: []
                }
                const formattedMonth = (newDay.getMonth() + 1) > 9 ? (newDay.getMonth() + 1) : "0" + (newDay.getMonth() + 1);
                const formattedDay = newDay.getDate() > 9 ? newDay.getDate() : "0" + newDay.getDate();
                const dayEvents = this.events.filter((event) => event.date === newDay.getFullYear().toString() + formattedMonth + formattedDay)
                let lastEvent = day.date
                for (let dayEvent of dayEvents) {
                    const displayEvent : DisplayEvent = {
                        top: (this.getDate(dayEvent.startDate).getTime() - lastEvent.getTime()) / 36000,
                        height: (this.getDate(dayEvent.endDate).getTime() - this.getDate(dayEvent.startDate).getTime()) / 36000,
                        event : dayEvent
                    }
                    lastEvent = this.getDate(dayEvent.endDate)
                    day.events.push(displayEvent)
                }
                this.week.push(day)
            }
        }
    }
});

