interface EdtInterface {
    id: string;
    name: string;
    icsCode: string;
    location: string;
}

export default class Edt implements EdtInterface {
    constructor(public id: string, public name: string, public icsCode: string, public location: string) {

    }
}

