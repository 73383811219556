import { createStore } from 'vuex'

export default createStore({
    state: {
        date: 0,
        lastEvent: 0
    },
    getters: {
    },
    mutations: {
        changeDate(state, newDate) {
            state.date = newDate;
        },
        changeLastEvent(state, lastEvent) {
            state.lastEvent = lastEvent;
        }
    },
    actions: {
    },
    modules: {
    }
})
