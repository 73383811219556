<template>
    <div v-bind:style="{height : displayEvent.height + 'px',marginTop: displayEvent.top + 'px'}" class="d-flex flex-row eventBox overflow-hidden w-100">
        <!--<div class="d-flex flex-column justify-content-evenly h-100 " style="border-right: 1px solid cornflowerblue; padding-right: 8px">
            <span>{{startDate}}</span>
            <span>{{endDate}}</span>
        </div>-->
        <div class="d-flex flex-column w-100">
            <span style="font-weight: bold">{{displayEvent.event.subject}}</span>
            <span>{{startDate}} - {{endDate}}</span>
            <!--<span>{{displayEvent.event.professor}}</span>-->
            <span>{{displayEvent.event.location}}</span>
            <!--<span>{{displayEvent.event.group}}</span>-->
        </div>
    </div>
</template>

<style scoped>
    .eventBox {
        display: flex;
        position: relative;
        align-items: center;
        background-color: #0d6efd;
        flex-direction: column;
        padding: 12px;
        border-radius: 10px;
        gap: 8px;
        text-overflow: ellipsis;
    }
    span {
        font-size: 16px;
        text-overflow: ellipsis;
        max-width: 100%;
    }

</style>

<script>
import DisplayEvent from "@/interfaces/DisplayEventInterface";


export default {
    methods: {
    },
    props: {
        displayEvent: {
            type: DisplayEvent,
            default() {
                return null
            }
        },
        startDate: {
            type: String,
            default: ""
        },
        endDate: {
            type: String,
            default: ""
        }
    }
}
</script>