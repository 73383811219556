import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomePage from "@/views/HomePage.vue";
import LoginPage from "@/views/LoginPage.vue";
import RegisterPage from "@/views/RegisterPage.vue";
import EdtPage from "@/views/EdtPage.vue";
import TimeTableBackground from "@/components/TimeTableBackground.vue";

const routes : Array<RouteRecordRaw> = [
    {path: "/edt",component: HomePage},
    {path: "/login", component: LoginPage},
    {path: "/register", component: RegisterPage},
    {path: "/",component: EdtPage},
    {path: "/test", component: TimeTableBackground}
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
